import { Chip } from "@material-tailwind/react";
import "react-toastify/dist/ReactToastify.css";
import { collection, getDocs, where, query } from "@firebase/firestore";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import { firestore } from "../firebase/firebaseSetup";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePWAInstall } from "react-use-pwa-install";
import { ToastContainer, toast } from "react-toastify";

export default function Home() {
  const install = usePWAInstall();
  const [loading, setLoading] = useState("");
  const [point, setPoint] = useState(0);
  const [mobile, setMobile] = useState("");
  const [sendHistory, setSendHistory] = useState([]);
  const [receiveHistory, setReceiveHistory] = useState([]);
  const [redeemHistory, setRedeemHistory] = useState([]);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  // const [withdrawHistory, setWithdrawHistory] = useState([]);
  const [historyType, setHistoryType] = useState("send");
  const getData = async () => {
    setLoading(true);
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      setPoint(user.data()["point"]);
      setMobile(user.data()["mobile"]);
      // console.log(user.data()['sendHistory'])
      setSendHistory(...sendHistory, user.data()["sendHistory"]);
      setReceiveHistory(...receiveHistory, user.data()["receiveHistory"]);
      setRedeemHistory(...redeemHistory, user.data()["redeemHistory"]);
      setSubscriptionHistory(
        ...subscriptionHistory,
        user.data()["subscriptionHistory"]
      );
      // setWithdrawHistory(...withdrawHistory, user.data()["withdrawHistory"]);
      // senHistory = user.data()['sendHistory']
    });
    setLoading(false);
  };

  const check = async () => {
    install && notify();
    install && console.log("test");
  };
  const notify = () => {
    toast("Install our app!", {
      position: "bottom-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };
  useEffect(() => {
    localStorage.getItem("email") && getData();
    check();
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      {loading ? (
        <div style={{ minHeight: "100vh" }}>
          <MDBSpinner className="mx-2" color="warning">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        </div>
      ) : localStorage.getItem("email") ? (
        <div>
          <MDBCard className="mb-2">
            <MDBCardHeader></MDBCardHeader>
            <MDBCardBody
              style={{
                border: "2px solid black",
                borderRadius: "20px",
              }}
            >
              <div className="d-flex justify-content-center mb-3">
                <Chip
                  variant="fill"
                  color="amber"
                  className="mr-2"
                  style={{ backgroundColor: "rgba(22,231,225,1)" }}
                  value={"point: " + point.toLocaleString("en-IN")}
                />
                <Chip
                  variant="fill"
                  color="green"
                  value={"৳ " + (point / 100).toLocaleString("en-IN")}
                />
              </div>
              <h4 style={{ textAlign: "left" }}>
                {"Fullname: " + localStorage.getItem("fullName")}
              </h4>
              <h4 style={{ textAlign: "left" }}>
                {"Username: @" + localStorage.getItem("username")}
              </h4>
              <h6 style={{ textAlign: "left" }}>Account number: {mobile}</h6>
            </MDBCardBody>
          </MDBCard>
          <Link to="/send">
            <MDBBtn className="mx-2" color="warning">
              Send
            </MDBBtn>
          </Link>
          <Link to="/coupon_withdraw">
            <MDBBtn className="mx-2" color="warning">
              Redeem coupon
            </MDBBtn>
          </Link>
          {/* <Link to="withdraw">
            <MDBBtn color="warning">Withdraw</MDBBtn>
          </Link> */}
          <MDBDropdown className="m-2">
            <MDBDropdownToggle>
              {historyType ? historyType + " history" : "Select history type"}
            </MDBDropdownToggle>
            <MDBDropdownMenu dark>
              <MDBDropdownItem link onClick={() => setHistoryType("send")}>
                Send history
              </MDBDropdownItem>
              <MDBDropdownItem link onClick={() => setHistoryType("receive")}>
                Receive history
              </MDBDropdownItem>
              {/* <MDBDropdownItem link onClick={() => setHistoryType("withdraw")}>
                Withdraw history
              </MDBDropdownItem> */}
              <MDBDropdownItem link onClick={() => setHistoryType("redeem")}>
                Redeem history
              </MDBDropdownItem>
              <MDBDropdownItem
                link
                onClick={() => setHistoryType("subscription")}
              >
                Subscription history
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
          <div className="d-flex flex-column justify-content-center">
            {sendHistory.length > 0 && historyType === "send" && (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Point/Taka</th>
                    <th scope="col">Account</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {sendHistory.map((item) => {
                    return (
                      <tr>
                        <th scope="row">
                          {item["date"]}
                          <br />
                          {item["time"]}
                        </th>
                        <td>
                          {item["amount"]} p<br />
                          {item["amount"] / 100} tk
                        </td>
                        <td>{item["mobile"]}</td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            )}
            {receiveHistory.length > 0 && historyType === "receive" && (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Point/Taka</th>
                    <th scope="col">Account</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {receiveHistory.map((item) => {
                    return (
                      <tr>
                        <th scope="row">
                          {item["date"]}
                          <br />
                          {item["time"]}
                        </th>
                        <td>
                          {item["amount"]} p<br />
                          {item["amount"] / 100} tk
                        </td>
                        <td>{item["mobile"]}</td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            )}
            {subscriptionHistory.length > 0 &&
              historyType === "subscription" && (
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">V</th>
                      <th scope="col">Date</th>
                      <th scope="col">Point/Taka</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {subscriptionHistory.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            {item["type"]} fee
                            <br />
                          </th>
                          <td>{item["version"]}</td>
                          <td>{item["date"]}</td>
                          <td>
                            {item["price"]} p<br />
                            {item["price"] / 100} tk
                          </td>
                        </tr>
                      );
                    })}
                  </MDBTableBody>
                </MDBTable>
              )}
            {/* {withdrawHistory.length > 0 && historyType === "withdraw" && (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th scope="col">Date/Time</th>
                    <th scope="col">Point/Taka</th>
                    <th scope="col">Account</th>
                    <th scope="col">Status</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {withdrawHistory.map((item) => {
                    return (
                      <tr>
                        <th scope="row">
                          {item["date"]}
                          <br />
                          {item["time"]}
                        </th>
                        <td>
                          {item["amount"]} p<br />
                          {item["amount"] / 100} tk
                        </td>
                        <td>{item["mobile"]}</td>
                        <td>{item["status"]}</td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            )} */}
            {redeemHistory &&
              redeemHistory.length > 0 &&
              historyType === "redeem" && (
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th scope="col">Date/Time</th>
                      <th scope="col">Point/Taka</th>
                      <th scope="col">Coupon</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {redeemHistory.map((item) => {
                      return (
                        <tr>
                          <th scope="row">
                            {item["date"]}
                            <br />
                            {item["time"]}
                          </th>
                          <td>
                            {item["point"]} p<br />
                            {item["point"] / 100} tk
                          </td>
                          <td>{item["coupon"]}</td>
                        </tr>
                      );
                    })}
                  </MDBTableBody>
                </MDBTable>
              )}
          </div>
        </div>
      ) : (
        <div
          style={{ height: "78vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h1>
            Please <Link to="/login">Login</Link> or{" "}
            <Link to="/register">Register</Link>
          </h1>
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        onClick={install}
        limit={1}
        draggable={false}
        theme="dark"
      />
    </div>
  );
}
