import React from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

import { firestore } from "../firebase/firebaseSetup";
import { collection, getDocs, where, query } from "@firebase/firestore";

export default function BottomBar() {
  const getData = async () => {
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      setPoint(user.data()["point"]);
    });
  };

  const [point, setPoint] = useState("");

  useEffect(() => {
    localStorage.getItem("email") && getData();
  }, []);

  return (
    <MDBNavbar
      fixed="bottom"
      sticky
      dark
      style={{
        maxWidth: "100%",
        maxHeight: "80px",
        border: "2px solid black",
        backgroundColor: "black",
      }}
    >
      <MDBContainer fluid>
        <MDBNavbarNav className="d-flex flex-row justify-content-evenly">
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="https://tfoodbd.com">
              <MDBNavbarLink style={{ color: "#FFCF3D" }}>
                <MDBIcon fas icon="home" />
                <p>Home</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="https://tfoodbd.com/offer">
              <MDBNavbarLink style={{ color: "#FFCF3D" }}>
                <MDBIcon fas icon="percentage" />
                <p>Offer</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="https://tfoodbd.com/catchup">
              <MDBNavbarLink style={{ color: "#FFCF3D" }}>
                <MDBIcon fas icon="utensils" />
                <p>Restaurant</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem className="me-3 me-lg-0">
            <Link to="https://tonmoybrand.com">
              <MDBNavbarLink style={{ color: "#00D820" }}>
                <MDBIcon fas icon="coins" />
                <p>Point</p>
              </MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
