import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";

export default function Profile() {
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [click, setClick] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const userRef = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    userRef.forEach(async (user) => {
      const getUser = doc(firestore, "users", user.id);
      await updateDoc(getUser, { mobile: mobile }).then((e) => {
        window.location.replace("/");
      });
    });
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      {/* <form onSubmit={submit}>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <MDBInput
            type="number"
            id="username"
            required
            label="Account number"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value.trim());
              setErrorMessage("");
            }}
          />
        </div>
        <MDBBtn disabled={click} className="mt-3" type="submit" color="warning">
          Submit
        </MDBBtn>
      </form> */}
    </div>
  );
}
