import { Chip } from "@material-tailwind/react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

export default function Send() {
  var time = new Date();
  const [mobile, setMobile] = useState("");
  const [amount, setAmount] = useState(null);
  const [click, setClick] = useState(false);
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(1000);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount > point) {
      setErrorMessage("Maximum limit " + point + " point");
    } else if (amount <= 0) {
      setErrorMessage("Invalid point");
    } else if (localStorage.getItem("mobile") === mobile) {
      setErrorMessage("Can't send to own account");
    } else {
      setClick(true);
      const receiverRef = query(
        collection(firestore, "users"),
        where("mobile", "==", mobile)
      );
      const senderRef = query(
        collection(firestore, "users"),
        where("mobile", "==", localStorage.getItem("mobile"))
      );
      const receiver = await getDocs(receiverRef);
      if (receiver.size > 0) {
        receiver.forEach(async (user) => {
          let sendHistory = {
            date:
              time.getDate() +
              "/" +
              (time.getMonth() + 1) +
              "/" +
              time.getFullYear(),
            time:
              (time.getHours() > 12
                ? time.getHours() - 12
                : time.getHours() === 0
                ? time.getHours() + 12
                : time.getHours()) +
              ":" +
              time.getMinutes() +
              (time.getHours() > 12 ? " PM" : " AM"),
            amount,
            mobile,
          };
          let receiveHistory = {
            date:
              time.getDate() +
              "/" +
              (time.getMonth() + 1).toString() +
              "/" +
              time.getFullYear(),
            time:
              (time.getHours() > 12
                ? time.getHours() - 12
                : time.getHours() === 0
                ? time.getHours() + 12
                : time.getHours()) +
              ":" +
              time.getMinutes() +
              (time.getHours() > 12 ? " PM" : " AM"),
            amount,
            mobile: localStorage.getItem("mobile"),
          };
          console.log(typeof user.data()["point"]);
          const getUser = doc(firestore, "users", user.id);
          await updateDoc(getUser, {
            point: parseInt(user.data()["point"]) + amount,
            receiveHistory: [receiveHistory, ...user.data()["receiveHistory"]],
          }).then(async () => {
            const sender = await getDocs(senderRef);
            sender.forEach(async (user) => {
              console.log(typeof user.data()["point"]);
              const getUser = doc(firestore, "users", user.id);
              await updateDoc(getUser, {
                point: parseInt(user.data()["point"]) - amount,
                sendHistory: [sendHistory, ...user.data()["sendHistory"]],
              });
            });
          });

          window.location.replace("/");
        });
      } else {
        setErrorMessage("Invalid number");

        setClick(false);
      }
    }
  };
  const getData = async () => {
    setLoading(true);
    const doc_refs = await getDocs(
      query(
        collection(firestore, "users"),
        where("email", "==", localStorage.getItem("email"))
      )
    );
    doc_refs.forEach((user) => {
      setPoint(user.data()["point"]);
    });
    setLoading(false);
  };

  useEffect(() => {
    localStorage.getItem("email") && getData();
    window.isSecureContext &&
      navigator.clipboard.readText().then((text) => {
        if (new RegExp("^\\d{11}$").test(text)) {
          setMobile(text);
        }
      });
  }, []);
  return (
    <MDBCard className="mt-2 pb-2">
      <MDBCardTitle>Send</MDBCardTitle>
      <MDBCardBody>
        {loading ? (
          <MDBSpinner className="mx-2" color="warning">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        ) : localStorage.getItem("email") ? (
          <div className="d-flex justify-content-center mb-3">
            <MDBCard background="warning">
              <MDBCardBody className="d-flex">
                <div className="d-flex">
                  <Chip
                    size="sm"
                    style={{ backgroundColor: "white" }}
                    color="amber"
                    className="mr-2"
                    value={
                      "Total amount: " +
                      point.toLocaleString("en-IN") +
                      " point/ " +
                      (point / 100).toLocaleString("en-IN") +
                      " taka"
                    }
                  />
                </div>
              </MDBCardBody>
            </MDBCard>
          </div>
        ) : (
          <p>Please login</p>
        )}

        {errorMessage && (
          <div
            className="d-flex justify-content-center"
            style={{ color: "red" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>
            <span>{errorMessage}</span>
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <MDBInput
              type="number"
              id="username"
              required
              label="Account number"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="mt-3 d-flex">
            <MDBInput
              type="number"
              value={amount}
              onChange={(e) => {
                setErrorMessage("");
                if (e.target.value > 0) {
                  setAmount(Number(e.target.value));
                } else {
                  setAmount(null);
                }
              }}
              label={amount > 0 ? "Taka: " + amount / 100 : "Point"}
            />
          </div>

          <MDBBtn
            disabled={click}
            className="mt-3"
            type="submit"
            color="warning"
          >
            Send
          </MDBBtn>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
}
