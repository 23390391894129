import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { collection, getDocs } from "@firebase/firestore";
import { firestore } from "../firebase/firebaseSetup";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [click, setClick] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleLogin = async (e) => {
    let loggedIn = false;
    e.preventDefault();
    setClick(true);
    const doc_refs = await getDocs(collection(firestore, "users"));
    await doc_refs.forEach((users) => {
      // users.push({
      //   id: users.id,
      //   ...users.data()
      // })
      if (
        users.data()["email"] === email &&
        users.data()["password"] === password
      ) {
        loggedIn = true;
        localStorage.setItem("email", email);
        localStorage.setItem("username", users.data()["username"]);
        localStorage.setItem("fullName", users.data()["fullName"]);
        localStorage.setItem("mobile", users.data()["mobile"]);
        localStorage.setItem("type", users.data()["type"]);
        window.location.replace("/");
        return;
      }
      // data.push({
      //   id: users.id,
      //   ...users.data()
      // })
    });
    if (!loggedIn) {
      setErrorMessage("Invalid email or password");
      setClick(false);
    }

    // data.forEach(res => {
    //   console.log(res['username']);
    // })
    // if (password !== confirmPassword) {
    //   setErrorMessage('Passwords do not match');
    // } else {
    //   let data = {
    //     username,
    //     fullName,
    //     email,
    //     'dateOfBirth': dob,
    //     gender,
    //     profession,
    //     religion,
    //     password,
    //     mobile,
    //     address,
    //   }
    //   addDoc(ref, data);
    // }
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      <h1>Login</h1>
      {errorMessage && <div>{errorMessage}</div>}
      <form onSubmit={handleLogin}>
        <div className="d-flex justify-content-around">
          <div className="my-2">
            <MDBInput
              type="text"
              required
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div className="my-2">
            <MDBInput
              type="password"
              label="Password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
        </div>
        <MDBBtn type="submit" disabled={click} color="warning" rounded>
          Login
        </MDBBtn>
      </form>
    </div>
  );
}
